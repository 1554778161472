<template>
	<div>
		<!-- page-wrapper Start-->
		<div class="page-wrapper d-flex justify-content-center">
			<b-card>
				<div class="error-wrapper">
					<div v-if="message == 'Success'" class="container">
						<div class="d-flex justify-content-center">
							<img class="img-100" src="../assets/images/other-images/smily.png" alt="">
						</div>
						<div class="d-flex justify-content-center">
							<h2 class="font-success">Payment Success.</h2>
						</div>
						<div class="d-flex justify-content-center">
							<p class="sub-content">You have successfully activated premium plan. Thank You...</p>
						</div>
						<div class="mt-3 d-flex justify-content-center"><router-link class="btn btn-info-gradien btn-lg" :to="{ path: '/app/dashboard' }">BACK TO HOME PAGE</router-link></div>
					</div>
					<div v-if="message == 'Error'" class="container">
						<div class="d-flex justify-content-center">
							<img class="img-100" src="../assets/images/other-images/sad1.png" alt="">
						</div>
						<div class="d-flex justify-content-center">
							<h2 class="font-danger">Payment Failed.</h2>
						</div>
						<div class="d-flex justify-content-center">
							<p class="sub-content">Plan Activation Failed. Plase try Again.</p>
						</div>
						<div class="mt-3 d-flex justify-content-center"><router-link class="btn btn-info-gradien btn-lg" :to="{ path: '/app/dashboard' }">BACK TO HOME PAGE</router-link></div>
					</div>
				</div>
			</b-card>
		</div>
	</div>
</template>
<script>
export default
{
name:"notifyPage",
data(){
    return{
        message : ''
    }
},
mounted(){
	const message = this.$route.query.message
    if(message)this.message = message  
}
}
</script>